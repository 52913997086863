<template>
  <div v-if="dataLoaded">
    <v-container class="pa-0">
      <!--app-view-toolbar-->
      <app-view-toolbar :title="headline" :showBackButton="true" :buttons="toolbarButtons" @onButton="onToolbarButton"></app-view-toolbar>
    </v-container>
    <v-container>
      <template v-if="view === 'work'">
        <v-expansion-panels
          class="mb-8 vajper-v-expansion"
          multiple
          v-model="expandedPanelIndexes"
          flat
        >
          <v-expansion-panel class="grey">
            <v-expansion-panel-header>Sök inkommande gods</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="d-flex align-center mb-4">
                <div class="text-subtitle-2 pr-2">Sök på</div>
                <v-btn-toggle
                  v-model="incomingGoodsSearchMode"
                  color="blue darken-4"
                  mandatory
                >
                  <v-btn
                    value="id"
                    text
                    small
                  >
                    ID
                  </v-btn>
                  <v-btn
                    value="deliveryNote"
                    text
                    small
                  >
                    Följesedel nr
                  </v-btn>
                </v-btn-toggle>
              </div>
              <v-text-field
                label="Ange nr"
                v-model="searchPhraseIncomingGoods"
                dense
                outlined
                background-color="white"
                @keydown.enter.prevent="searchIncomingGoods()"
                hide-details
              >
              </v-text-field>
              <div v-if="viewData.listOfIncomingGoods.length">
                <v-simple-table class="mb-4 mt-4 full-width">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Nr
                        </th>
                        <th class="text-left">
                          Följesedel
                        </th>
                        <th class="text-left">
                          Datum
                        </th>
                        <th class="text-left">
                          Leverantör
                        </th>
                        <th class="text-left">
                          LS
                        </th>
                        <th class="text-left">
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="incomingGoods in viewData.listOfIncomingGoods"
                        :key="incomingGoods.id"
                      >
                        <td>{{ incomingGoods.id }}</td>
                        <td>{{ incomingGoods.deliveryNoteId }}</td>
                        <td class="text-no-wrap">{{ incomingGoods.date }}</td>
                        <td>{{ incomingGoods.supplierName }}</td>
                        <td>{{ incomingGoods.stockPointCode }}</td>
                        <td class="text-no-wrap">
                          <v-tooltip
                            left
                            dark
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-on="on"
                                @click="removeIncomingGoods(incomingGoods.id)"
                                color="red"
                              >
                                mdi-trash-can-outline
                              </v-icon>
                            </template>
                            <span>Ta bort inkommande gods från vyn</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-row>
            <v-col cols="12" md="6">
              <v-expansion-panel class="grey" v-show="okToShowProductConsideration">
                <v-expansion-panel-header>Varuavstämning</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-text-field
                    label="Ange produktnummer / EAN"
                    v-model="searchPhraseProduct"
                    dense
                    outlined
                    background-color="white"
                    @keyup.enter.prevent="searchProduct"
                    hide-details
                    ref="refProductSearch"
                  >
                  </v-text-field>
                  <div v-if="!!foundProduct">
                    <v-simple-table class="my-4" dense>
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="productData in viewData.productDatas"
                            :key="productData.label"
                            :class="{'header-row': productData.header}"
                          >
                            <td class="text-left">
                              {{ productData.label }}
                            </td>
                            <td class="text-left">
                              <template v-if="productData.type && productData.type == 'name'">
                                {{ foundProduct.itemDescription }}
                              </template>
                              <template v-else>
                                {{ productData.value }}
                              </template>
                            </td>
                          </tr>
                          <tr
                            v-for="stock in viewData.stocks"
                            :key="stock.value"
                          >
                            <td class="text-left">
                              {{ stock.name }}
                            </td>
                            <td class="text-left">
                              {{ stock.qty }} st
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-form ref="formConsiderProduct" lazy-validation @submit.prevent="null">
                      <v-simple-table class="mb-4" dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th v-if="isWorkingWithMultipleIncomingGoods">
                                I.G
                              </th>
                              <th class="text-left" colspan="2">
                                Antal
                              </th>
                              <th class="text-right">
                                Väntas / Beställt
                              </th>
                              <th class="text-left" title="Inköpsorder nr">
                                I.O.
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="row in editableRows"
                              :key="row.id"
                            >
                              <td v-if="isWorkingWithMultipleIncomingGoods">
                                {{ row.incomingGoodsId }}
                              </td>
                              <td class="text-left">
                                <v-text-field
                                  v-model="row.receivedQuantity"
                                  class="number size-5"
                                  type="number"
                                  dense
                                  outlined
                                  background-color="white"
                                  suffix="st"
                                  hide-details
                                  :min="0"
                                  :rules="[rules.positiveInteger(row.receivedQuantity, { min: 0 })]"
                                  ref="refRecievedQuantity"
                                  @keyup.enter.prevent="considerProduct"
                                />
                              </td>
                              <td>
                                <div v-if="row.orderedQuantity !== 0 && row.receivedQuantity > row.remainingOrderedQuantity" style="color:firebrick" class="text-caption">{{ row.receivedQuantity - row.remainingOrderedQuantity }} fler än väntat</div>
                                <div v-if="row.orderedQuantity === 0" class="text-caption">Fler än beställt antal</div>
                              </td>
                              <td class="text-right text-no-wrap">{{ row.remainingOrderedQuantity }} st / {{ row.orderedQuantity }} st</td>
                              <td>{{ row.purchaseOrderId }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-form>
                    <v-btn
                      small
                      @click="considerProduct()"
                      color="primary"
                    >
                      <v-icon left>mdi-check</v-icon>
                      Stäm av
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-col>
            <v-col cols="12" md="6">
              <v-expansion-panel class="grey" v-show="viewData.latestOrders">
                <v-expansion-panel-header>Olevererade kundordrar</v-expansion-panel-header>
                <v-expansion-panel-content v-if="viewData.latestOrders">
                  <div class="text-subtitle-2" v-if="!viewData.latestOrders.length">
                    Inga ordrar finns
                  </div>
                  <div v-else>
                    <v-simple-table class="mb-4 full-width click-table" dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Nr
                            </th>
                            <th class="text-left">
                              Lagd
                            </th>
                            <th class="text-left">
                              Köpare
                            </th>
                            <th class="text-right" title="Olevererat antal">
                              O.A.
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="latestOrder in viewData.latestOrders"
                            :key="latestOrder.ohd_id"
                            @click="openOrder(latestOrder.ohd_id)"
                          >
                            <td>{{ latestOrder.ohd_id }}</td>
                            <td class="text-no-wrap">{{ latestOrder.ohd_dat_created.split('T')[0] }}</td>
                            <td>{{ latestOrder.ohd_str_shipping_company }}</td>
                            <td class="text-right text-no-wrap" title="Olevererat antal">{{ latestOrder.undelivered_qty }} st</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div class="text-caption">
                      * O.A. = Olevererat antal
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-col>
          </v-row>
          <v-expansion-panel class="red mt-6" v-show="hasIncomingGoods">
            <v-expansion-panel-header>Oavstämda produkter</v-expansion-panel-header>
            <v-expansion-panel-content v-if="hasIncomingGoods">
              <v-row>
                <v-col cols="12">
                  <div v-if="!hasUnconsideredProducts" class="text-caption">
                    Inga finns
                  </div>
                  <template v-else>
                    <v-simple-table class="mb-4 click-table" dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-right" title="Inkommande Gods">
                              I.G.
                            </th>
                            <th class="text-right">
                              Väntas / Beställt
                            </th>
                            <th class="text-left">
                              Art nr
                            </th>
                            <th class="text-left">
                              Vara
                            </th>
                            <th class="text-right">
                              Mottaget
                            </th>
                            <th class="text-right" title="Inköpsorder">
                              I.O.
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="row in unconsideredRows"
                            :key="row.id"
                            @click="onProductClick(row)"
                          >
                            <td class="text-right" title="Inkommande Gods">{{ row.incomingGoodsId }}</td>
                            <td class="text-right text-no-wrap">{{ row.remainingOrderedQuantity }} st / {{ row.orderedQuantity }} st</td>
                            <td>{{ row.itemId }}</td>
                            <td>{{ row.itemDescription }}</td>
                            <td class="text-right text-no-wrap">{{ row.receivedQuantity }} st</td>
                            <td class="text-right" title="Inköpsorder">{{ row.purchaseOrderId }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div class="text-caption">
                      * I.G. = Inkommande goods
                      <br />
                      * I.O. = Inköpsorder
                    </div>
                  </template>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="green mt-6" v-show="hasIncomingGoods">
            <v-expansion-panel-header>Avstämda produkter</v-expansion-panel-header>
            <v-expansion-panel-content v-if="hasIncomingGoods">
              <v-row>
                <v-col cols="12">
                  <div v-if="!hasConsideredProducts" class="text-caption">
                    Inga finns
                  </div>
                  <template v-else>
                    <v-simple-table class="mb-4 click-table" dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-right" title="Inkommande Gods">
                              I.G.
                            </th>
                            <th class="text-right">
                              Väntas / Beställt
                            </th>
                            <th class="text-left">
                              Art nr
                            </th>
                            <th class="text-left">
                              Vara
                            </th>
                            <th class="text-right">
                              Mottaget
                            </th>
                            <th class="text-right" title="Inköpsorder">
                              I.O.
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="row in consideredRows"
                            :key="row.id"
                            @click="onProductClick(row)"
                          >
                            <td class="text-right" title="Inkommande Gods">{{ row.incomingGoodsId }}</td>
                            <td class="text-right">
                              <template v-if="row.orderedQuantity !== 0">{{ row.remainingOrderedQuantity }} st / {{ row.orderedQuantity }} st</template>
                              <template v-if="row.orderedQuantity === 0">Fler än beställt antal</template>
                            </td>
                            <td>{{ row.itemId }}</td>
                            <td>{{ row.itemDescription }}</td>
                            <td class="text-right">{{ row.receivedQuantity }} st</td>
                            <td class="text-right" title="Inköpsorder">{{ row.purchaseOrderId }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div class="text-caption">
                      * I.G. = Inkommande goods
                      <br />
                      * I.O. = Inköpsorder
                    </div>
                  </template>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
      <template v-if="view === 'searchResult'">
        <div class="d-flex justify-start align-center mb-4">
          <v-btn
            small
            text
            class="pr-0 pl-0 back-button"
            @click="view = 'work'"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <div class="text-title-2 m-0">Välj en av sökträffarna</div>
        </div>
        <v-simple-table class="mb-4 mt-4 full-width click-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Nr
                </th>
                <th class="text-left">
                  Följesedel
                </th>
                <th class="text-left">
                  Leverantör
                </th>
                <th class="text-left">
                  Datum
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="incomingGoods in viewData.incomingGoodsResults"
                :key="incomingGoods.id"
                @click="addIncomingGoodsHit(incomingGoods)"
              >
                <td>{{ incomingGoods.id }}</td>
                <td>{{ incomingGoods.deliveryNoteId }}</td>
                <td>{{ incomingGoods.supplierName }}</td>
                <td class="text-no-wrap">{{ incomingGoods.date }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </v-container>
    <!--confirm for releasing incoming goods-->
    <app-confirm :show="showReleaseIncomingGoodsConfirm" :title="releaseIncomingGoodsConfirmTitle" text="Är du säker på att du ändå vill sätta leverans klar?" @onYes="executeReleaseIncomingGoods" @onNo="showReleaseIncomingGoodsConfirm = false"></app-confirm>
  </div>
</template>
<script>
import { onMounted, computed, ref, reactive, watch } from '@vue/composition-api'
import useCore from '@/helpers/core'
import useValidation from '@/helpers/validation'
import ViewToolbar from '@/components/ViewToolbar.vue'
import Confirm from '@/components/Confirm.vue'
import { appBus } from '@/main'

export default {
  name: 'Customer',
  components: {
    'app-view-toolbar': ViewToolbar,
    'app-confirm': Confirm,
  },
  setup() {
    const store = require('@/store/store').default;
    // const router = require('@/router/router').default;

    const useFullscreenDialog = store.getters.useFullscreenDialog;

    const { callApi, showMessage, openOrderInKobra } = useCore();
    const { rules } = useValidation();

    const expansionPanelLabels = ['search-incoming-goods', 'search-product', 'latest-orders', 'unconsidered-products', 'considered-products'];

    const headline = 'Inkommande gods';
    const dataLoaded = ref(false);
    const searchPhraseIncomingGoods = ref('');
    const searchPhraseProduct = ref('');
    const searchedProductNumber = ref('');
    const incomingGoodsSearchMode = ref('id');
    const expandedPanelIndexes = ref([0,1,2,3,4]);
    const foundProduct = ref(null);
    const editableRows = ref(null);
    const showReleaseIncomingGoodsConfirm = ref(false);
    const formConsiderProduct = ref(null);
    const view = ref('work');
    const refRecievedQuantity = ref([]);
    const refProductSearch = ref(null);

    const viewData = reactive({
      listOfIncomingGoods: [],
      incomingGoodsResults: null,
      latestOrders: null,
      stocks: null,
      consideredRowIds: null,
      productDatas: null,
    });

    const hasIncomingGoods = computed(() => {
      return viewData.listOfIncomingGoods.length;
    });

    const okToShowProductConsideration = computed(() => {
      return hasIncomingGoods.value && hasAnyUnreleasedIncomingGoods.value;
    });

    const hasAnyUnreleasedIncomingGoods = computed(() => {
      return !!viewData.listOfIncomingGoods.find((incomingGoods) => !incomingGoods.released);
    });

    const hasConsideredProducts = computed(() => {
      return hasIncomingGoods.value && viewData.consideredRowIds && viewData.consideredRowIds.length;
    });

    const isWorkingWithMultipleIncomingGoods = computed(() => {
      return viewData.listOfIncomingGoods.length > 1;
    });

    const hasUnconsideredProducts = computed(() => {
      return hasIncomingGoods.value && (!hasConsideredProducts.value || allRows.value.length > viewData.consideredRowIds.length);
    });

    const releaseIncomingGoodsConfirmTitle = computed(() => {
      if (!hasUnconsideredProducts.value) {
        return '';
      }
      if (unconsideredRows.value.length === 1) {
        return 'Du har 1 oavstämd produkt';
      }
      return 'Du har ' + unconsideredRows.value.length + ' oavstämda produkt';
    });

    const allRows = computed(() => {
      return viewData.listOfIncomingGoods.reduce((items, incomingGoods) => {
        for (let row of incomingGoods.rows) {
          items.push({...row, incomingGoodsId: incomingGoods.id});
        }
        return items;
      }, []);
    });

    const consideredRows = computed(() => {
      if (!hasIncomingGoods.value || !hasConsideredProducts.value) {
        return [];
      }
      return allRows.value.reduce((items, row) => {
        if (viewData.consideredRowIds.includes(row.id)) {
          items.push({...row});
        }
        return items;
      }, []);
    });

    const unconsideredRows = computed(() => {
      if (!hasIncomingGoods.value) {
        // nothing to show
        return [];
      }
      console.log("computed unconsideredRows", allRows.value);
      return allRows.value.reduce((items, row) => {
        if (!consideredRows.value.length || !viewData.consideredRowIds.includes(row.id)) {
          items.push({...row});
        }
        return items;
      }, []);
    });

    const toolbarButtons = computed(() => {
      switch (view.value) {
        case 'searchResult':
          return [];
        default:
          if (hasAnyUnreleasedIncomingGoods.value) {
            return [
              {
                label: 'releaseIncomingGoods',
                icon: 'check',
                name: 'Klart',
              }
            ];
          }
          return [
            {
              label: 'releaseIncomingGoods',
              icon: 'check',
              name: 'Klart',
              disabled: true,
            }
          ];
      }
    });

    watch(() => viewData.listOfIncomingGoods, () => {
      if (viewData.listOfIncomingGoods.length) {
        // make a deep copy of the matching rows among all incoming goods and assign them to editableRows
        editableRows.value = viewData.listOfIncomingGoods.reduce((items, incomingGoods) => {
          for (let row of incomingGoods.rows) {
            if (row.itemId.toUpperCase() === searchedProductNumber.value) {
              // push row + incomingGoods id
              items.push({incomingGoodsId: incomingGoods.id, ...row});
            }
          }
          return items;
        }, []);
      } else {
        editableRows.value = [];
      }
    });

    watch(() => viewData.incomingGoodsResults, () => {
      if (viewData.incomingGoodsResults) {
        view.value = 'searchResult';
      } else {
        view.value = 'work';
      }
    });

    const ensureExpandedPanel = (label) => {
      const index = expansionPanelLabels.indexOf(label);
      console.log('ensureExpandedPanel', label, index, expandedPanelIndexes.value, expandedPanelIndexes.value.includes(index));
      if (!expandedPanelIndexes.value.includes(index)) {
        expandedPanelIndexes.value.push(index);
        console.log('pushed!', expandedPanelIndexes.value);
      }
    }

    const onData = (referer, response) => {
      console.log('onData', referer);
      viewData.incomingGoodsResults = null;
      switch (referer) {
        case 'addIncomingGoods':
          viewData.latestOrders = null;
          searchPhraseIncomingGoods.value = '';
          if (response.hitCount == 0) {
            showMessage('Inget inkommande gods kunde hittas');
          } else {
            focusProductSearch();
          }
          viewData.listOfIncomingGoods = response.listOfIncomingGoods;
          break;
        case 'searchIncomingGoods':
          if (response.listOfIncomingGoods) {
            // perfect match found, so add it in the same manner as 'addIncomingGoods'
            viewData.listOfIncomingGoods = response.listOfIncomingGoods;
            focusProductSearch();
          } else if (response.incomingGoodsResults.length === 0) {
            // none could be found
            showMessage('Inget inkommande gods kunde hittas');
          } else {
            // one or more result without a perfect match - show search result
            viewData.incomingGoodsResults = response.incomingGoodsResults;
          }
          break;
        case 'mounted':
          viewData.listOfIncomingGoods = response.listOfIncomingGoods;
          if (response.consideredRowIds) {
            viewData.consideredRowIds = response.consideredRowIds;
          }
          break;
        case 'fetchProduct':
          // set searchedProductNumber first cause it is needed in the function that watches listOfIncomingGoods
          searchedProductNumber.value = response.searchedProductNumber.toUpperCase();
          viewData.latestOrders = response.ohds;
          viewData.stocks = response.stocks;
          viewData.productDatas = response.productDatas;
          viewData.listOfIncomingGoods = response.listOfIncomingGoods;
          viewData.consideredRowIds = response.consideredRowIds;
          ensureExpandedPanel('latest-orders');
          if (viewData.productDatas.length) {
            focusProductQuantity();
          }
          break;
        case 'productConsidered':
          viewData.listOfIncomingGoods = response.listOfIncomingGoods;
          viewData.consideredRowIds = response.consideredRowIds;
          resetProductSearch();
          focusProductSearch();
          break;
        case 'incomingGoodsReleased':
          // rest listOfIncomingGoods
          viewData.listOfIncomingGoods = [];
          resetProductSearch();
          break;
        case 'incomingGoodsRemoved':
          viewData.listOfIncomingGoods = response.listOfIncomingGoods;
          resetProductSearch();
          break;
      }

      dataLoaded.value = true;
    };

    const setOverlay = (show = false, message = null) => {
      if (show) {
        appBus.$emit('overlay', { message });
      } else {
        appBus.$emit('overlay');
      }
    }

    const getCurrentIncomingGoodsIds = () => {
      return viewData.listOfIncomingGoods.map(incomingGoods => incomingGoods.id);
    }

    const addIncomingGoodsHit = (incomingGoods) => {
      setOverlay(true);
      const currentIncomingGoodsIds = getCurrentIncomingGoodsIds();
      callApi({
        method: 'post',
        path: '/cms/incoming-goods/add',
        data: {
          currentIncomingGoodsIds,
          id: incomingGoods.id,
        }
      })
        .then((apiResponse) => {
          onData('addIncomingGoods', apiResponse);
          viewData.incomingGoodsResults = null;
          setOverlay();
        })
        .catch((err) => {
          setOverlay();
          return err;
        });

    }

    const resetProductSearch = () => {
      searchPhraseProduct.value = '';
      viewData.latestOrders = null;
      foundProduct.value = null;
      viewData.stocks = null;
      viewData.productDatas = null;
    }

    const searchIncomingGoods = () => {
      const q = searchPhraseIncomingGoods.value.toString().trim();
      if (!q.length) {
        return;
      }
      // blank product search
      resetProductSearch();
      setOverlay(true);
      const currentIncomingGoodsIds = getCurrentIncomingGoodsIds();
      switch (incomingGoodsSearchMode.value) {
        case 'id':
          callApi({
            method: 'post',
            path: '/cms/incoming-goods/add',
            data: {
              currentIncomingGoodsIds,
              id: q,
            }
          })
            .then((apiResponse) => {
              onData('addIncomingGoods', apiResponse);
              setOverlay();
            })
            .catch((err) => {
              setOverlay();
              return err;
            });
          break;
        case 'deliveryNote':
          callApi({
            method: 'post',
            path: '/cms/incoming-goods/search',
            data: {
              mode: incomingGoodsSearchMode.value,
              currentIncomingGoodsIds,
              q,
            }
          })
            .then((apiResponse) => {
              onData('searchIncomingGoods', apiResponse);
              setOverlay();
            })
            .catch((err) => {
              setOverlay();
              return err;
            });
          break;
      }
    }

    const focusProductSearch = () => {
      setTimeout(function() {
        refProductSearch.value.$refs.input.focus();
      }, 350);
    }

    const focusProductQuantity = () => {
      setTimeout(function() {
        refRecievedQuantity.value[0].$refs.input.focus();
      }, 350);
    }

    const fetchProduct = (q) => {
      const involvedPrdNumbers = allRows.value.map((row) => row.itemId.toUpperCase());
      const isProductNumberMatch = involvedPrdNumbers.includes(q.toUpperCase());
      // find match in rows - NOT SINCE WE ALLOW EAN SEARCH
      // foundProduct.value = allRows.value.find((row) => row.itemId.toLowerCase() === q.toLowerCase());
      // q must also be present in incomingGoods rows to allow to be have product data fetched
      // fetch data on enter key press
      // if (!foundProduct.value) {
        // showMessage('Produkten förekommer ej aktuellt inkommande gods');
        // return;
      // }
      setOverlay(true);
      // get latest orders, stock data etc for product
      viewData.latestOrders = null;
      callApi({
        method: 'post',
        path: '/cms/incoming-goods/search-product',
        data: {
          incomingGoodsIds: viewData.listOfIncomingGoods.map(incomingGoods => incomingGoods.id),
          q,
          isProductNumberMatch,
          involvedPrdNumbers,
        }
      })
        .then((apiResponse) => {
          onData('fetchProduct', apiResponse);
          foundProduct.value = true;
          setOverlay();
        })
        .catch((err) => {
          setOverlay();
          foundProduct.value = false;
          return err;
        });
    }

    const searchProduct = () => {
      const q = searchPhraseProduct.value.toString().trim();
      if (!q.length) {
        return;
      }
      fetchProduct(q);
    }

    const onProductClick = (product) => {
      if (!product.incomingGoodsIsReleased) {
        searchPhraseProduct.value = product.itemId;
        fetchProduct(product.itemId);
      }
    }

    const considerProduct = () => {
      const formIsValid = formConsiderProduct.value.validate();
      if (!formIsValid) {
        return;
      }
      setOverlay(true);
      const involvedIncomingGoodsIds = [];
      // update rows in incomingGoods.rows in viewData.listOfIncomingGoods
      for (let editableRow of editableRows.value) {
        for (let incomingGoods of viewData.listOfIncomingGoods) {
          let rowHit = incomingGoods.rows.find((row) => row.id == editableRow.id);
          if (rowHit) {
            // update receivedQuantity parameter of incomingGoods.rows in listOfIncomingGoods
            rowHit.receivedQuantity = editableRow.receivedQuantity;
            if (!involvedIncomingGoodsIds.includes(incomingGoods.id)) {
              // if not already added, add incoming goods id to involvedIncomingGoodsIds
              involvedIncomingGoodsIds.push(incomingGoods.id);
            }
          }
        }
      }

      // save to fortnox
      callApi({
        method: 'post',
        path: '/cms/incoming-goods/update',
        data: {
          consideredRows: editableRows.value,
          incomingGoodsIds: viewData.listOfIncomingGoods.map(incomingGoods => incomingGoods.id),
        }
      })
        .then((apiResponse) => {
          onData('productConsidered', apiResponse);
          setOverlay();
        })
        .catch((err) => {
          setOverlay();
          return err;
        });
    }

    const releaseIncomingGoods = () => {
      if (hasUnconsideredProducts.value) {
        // if have unconsidered products left, require a confirmation in order to proceed
        showReleaseIncomingGoodsConfirm.value = true;
      } else {
        // otherwise ok to proceed instantly
        executeReleaseIncomingGoods();
      }
    }

    const executeReleaseIncomingGoods = () => {
      showReleaseIncomingGoodsConfirm.value = false;
      setOverlay(true);
      // transfer receivedQuantities from editableRows to allRows
      for (let editableRow of editableRows.value) {
        allRows.value.find((row) => row.id == editableRow.id).receivedQuantity = editableRow.receivedQuantity;
      }
      const ids = viewData.listOfIncomingGoods.map((incomingGoods) => incomingGoods.id);
      // save to fortnox
      callApi({
        method: 'post',
        path: '/cms/incoming-goods/release',
        data: {
          ids,
        }
      })
        .then((apiResponse) => {
          showMessage(apiResponse.message);
          onData('incomingGoodsReleased', apiResponse);
          setOverlay();
        })
        .catch((err) => {
          setOverlay();
          return err;
        });
    }

    const removeIncomingGoods = (id) => {
      setOverlay(true);
      // compose array of all ids but the one to be removed
      const ids = viewData.listOfIncomingGoods.filter((incomingGoods) => incomingGoods.id !== id).map((incomingGoods) => incomingGoods.id);
      callApi({
        method: 'post',
        path: '/cms/incoming-goods/set-working-ids',
        data: {
          ids,
        }
      })
        .then((apiResponse) => {
          onData('incomingGoodsRemoved', apiResponse);
          setOverlay();
        })
        .catch((err) => {
          setOverlay();
          return err;
        });
    }

    const openOrder = (id) => {
      setOverlay(true);
      openOrderInKobra(id, () => {
        setOverlay();
      });
    }

    const onToolbarButton = button => {
      switch (button.label) {
        case 'releaseIncomingGoods':
          releaseIncomingGoods();
        break;
      }
    }

    const doOnMounted = () => {
      callApi({
        method: 'get',
        path: '/cms/incoming-goods/on-mount',
      })
        .then((apiResponse) => {
          onData('mounted', apiResponse);
        })
        .catch((err) => {
          return err;
        });
    };

    onMounted(doOnMounted);

    return {
      headline,
      toolbarButtons,
      dataLoaded,
      rules,
      useFullscreenDialog,
      onToolbarButton,
      viewData,
      searchPhraseIncomingGoods,
      searchPhraseProduct,
      searchIncomingGoods,
      incomingGoodsSearchMode,
      onProductClick,
      searchProduct,
      expandedPanelIndexes,
      openOrder,
      considerProduct,
      foundProduct,
      okToShowProductConsideration,
      editableRows,
      hasIncomingGoods,
      hasConsideredProducts,
      hasUnconsideredProducts,
      consideredRows,
      unconsideredRows,
      releaseIncomingGoods,
      showReleaseIncomingGoodsConfirm,
      executeReleaseIncomingGoods,
      releaseIncomingGoodsConfirmTitle,
      // incomingGoodsIsEditable,
      formConsiderProduct,
      removeIncomingGoods,
      view,
      addIncomingGoodsHit,
      refRecievedQuantity,
      refProductSearch,
      isWorkingWithMultipleIncomingGoods,
    }
  },
};
</script>

<style>
  tr.header-row > td {
    background-color: rgba(0,0,0,0.05);
    font-weight: 500;
  }
</style>

