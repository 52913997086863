export default function () {
	const rules = {
		requiredNumber: value => (value !== null && value >= 0) || 'Obligatoriskt',
		required: value => (value !== null && value.toString().trim().length !== 0) || 'Obligatoriskt',
		requiredRadioGroup: (value, message = 'Val måste göras') => {
			// console.log('rules.requiredRadioGroup value', value);
      // console.log('rules.requiredRadioGroup typeof value', typeof value);
			switch (typeof value) {
				case 'object':
					// possible null value checked
					return value === null || message;
				case 'undefined':
					// unchecked radio button group
					return message;
			}
			return true;
		},
		requiredCombobox: (value, message = 'Obligatoriskt') => {
			console.log('rules.requiredCombobox value', value);
      console.log('rules.requiredCombobox typeof value', typeof value);
			switch (typeof value) {
				case 'object':
					// option selected
					return value === null || value.text.length !== 0 || message;
				default:
					// input value
					return value.toString().trim().length !== 0 || message;
			}
		},
		positiveInteger(value, limitData) {
			let has_min = limitData && limitData.min !== undefined
			let has_max = limitData && limitData.max !== undefined
			if (value === null || value.toString().trim().length == 0) {
				return true
			}
			const pattern = /^[0-9]+$/;
			const isPositiveInteger = pattern.test(value.toString().trim())
			//is positive integer
			if (has_min && has_max) {
				//has both min and max
				if (!isPositiveInteger || value < limitData.min || value > limitData.max) {
					return 'Ange ett heltal mellan ' + limitData.min + ' och ' + limitData.max
				}
				return true
			}
			if (has_min) {
				//has only min
				if (!isPositiveInteger || value < limitData.min) {
					return 'Ange ett heltal som minst ' + limitData.min
				}
				return true
			}
			if (has_max) {
				//has only max
				if (!isPositiveInteger || value < limitData.max) {
					return 'Ange ett heltal som max ' + limitData.max
				}
				return true
			}
			if (!isPositiveInteger) {
				return 'Ange ett positivt heltal'
			}
			return true
		},
// 		requiredMultipleCheckboxes: (value, message = 'Val måste göras') => {
// //          console.log('formRules.checked', value)
//          console.log('formRules.checked typeof value', typeof value)
// 			switch (typeof value) {
// 				case 'boolean':
// 					//single checkbox
// 					return value || message
// 				case 'object':
// 				case 'undefined':
// 					// unchecked radio button group
// 					return message;
// 			}
// 			//multiple checkboxes
// 			return value.length !== 0 || message
// 		},
		organizationNumber: value => {
			const pattern = /^[0-9]{6}-[0-9]{4}$/
			return (value.toString().trim().length == 0 || pattern.test(value.toString().trim())) || 'Ange i formatet NNNNNN-NNNN'
		},
		personNumber: value => {
			const pattern = /^[0-9]{8}-[0-9]{4}$/
			return (value.toString().trim().length == 0 || pattern.test(value.toString().trim())) || 'Ange i formatet ÅÅÅÅMMDD-NNNN'
		},
		email: value => {
			const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return (value === null || value.toString().trim().length == 0 || pattern.test(value.toString().trim())) || 'Ogiltig e-postadress'
		},
		date: value => {
			const pattern = /^\d{4}-\d{2}-\d{2}$/;
			return (value.toString().trim().length == 0 || pattern.test(value.toString().trim())) || 'Ange i formatet YYYY-MM-DD';
		},
		time: value => {
			const pattern = /^\d{2}:\d{2}$/;
			return (value.toString().trim().length == 0 || pattern.test(value.toString().trim())) || 'Ange i formatet HH:MM';
		},
	}

	const ensureJsComma = value => {
		return value.toString().replace(',', '.');
	}

	const restoreNumeric = (value, allowNegative, min = null, max = null, decimalCount = null) => {
		if (value === null || value.toString().trim().length == 0) {
			return '';
		}
		value = ensureJsComma(value);
		const myRe = (allowNegative) ? new RegExp("[^\\d\\.\\-]","g") : new RegExp("[^\\d\\.]","g");
		value = value.replace(myRe, '');
		if (isNumeric(value)) {
			// force min / max / decimalCount
			if (min !== null && value < min) {
				value = min;
      }
			if (max !== null && value > max) {
				value = max;
      }
			if (decimalCount !== null && getDecimalCount(value) > decimalCount) {
				value = parseFloat(value).toFixed(decimalCount);
      }
		}
    return value;
	}

	const restoreInteger = (value, options = {}) => {
		// options: { allowNegative, min, max }
		if (value === null || value.toString().trim().length == 0) {
			return '';
		}
		value = ensureJsComma(value);
		const myRe = options.allowNegative ? new RegExp("[^\\d\\-]","g") : new RegExp("[^\\d]","g");
		value = value.replace(myRe, '');
		if (isInteger(value)) {
			//force min / max
			if (options.min !== undefined && value < options.min) {
				value = options.min;
			}
			if (options.max !== undefined && value > options.max) {
				value = options.max;
			}
		}
		return value;
	}

	const isNumeric = value => {
		return value.toString().trim().length != 0 && !isNaN(value);
	}

	const isInteger = (value, allowNegative = false) => {
		var str = value.toString();
		if (str.length == 0) {
			return false;
		}
		if (allowNegative) {
			return (str.search(/^-?[0-9]+$/) == 0);
		}
		return (str.search(/^[0-9]+$/) == 0);
	}

	const getDecimalCount = value => {
		if (!hasDecimal(value)) {
			return 0;
    }
		return value.toString().split(".")[1].length || 0;
	}

	const hasDecimal = value => {
		return value != parseInt(value);
	}

  return {
		rules,
    restoreNumeric,
    isNumeric,
		isInteger,
    getDecimalCount,
    hasDecimal,
		restoreInteger,
  }
}
