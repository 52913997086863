<template>
  <v-dialog
    v-model="show"
    style="z-index:1000000"
    :fullscreen="useFullscreenDialog"
    :max-width="useFullscreenDialog ? 10000 : 400"
  >
    <v-card>
      <p class="text-h6 text-center pt-4 pb-0">{{ title }}</p>
      <v-card-text class="text-center" v-if="text">
        {{ text }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="onNo">Nej</v-btn>
        <v-btn color="green darken-1" text @click="onYes">Ja</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { watch } from '@vue/composition-api'
import useCore from '@/helpers/core'

export default {
  name: 'Confirm',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const store = require('@/store/store').default;

    const { tellKobra } = useCore();

    const useFullscreenDialog = store.getters.useFullscreenDialog;

    const onNo = () => {
      context.emit('onNo');
    }

    const onYes = () => {
      context.emit('onYes');
    }

    // watch
    watch(() => props.show, newValue => {
      if (newValue && useFullscreenDialog) {
        tellKobra('scrollToTop');
      }
    });

    return {
      onNo,
      onYes,
      useFullscreenDialog,
    }
  }
}
</script>